export enum LOCAL_STORAGE_KEY_ENUM {
    CURRENT_WALLET = 'current_wallet',
    TOKEN = 'token',
    TWITTER_USER_INFO = 'twitterUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    DISCORD_USER_INFO = 'discordUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    TELEGRAM_USER_INFO = 'telegramUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    SOCIAL_MEDIA_TYPE = 'socialMediaType', // 社交媒体授权跳转前的社交媒体类型
    SOCIAL_MEDIA_AUTH = 'social_media_auth', // 社交媒体授权跳转前的社交媒体类型

    SOCIALSCAN_HOMEPAGE_VIEWED = 'socialscan_homepage_viewed',
    SOCIALSCAN_MINING_VIEWED = 'socialscan_mining_viewed',
    SOCIALSCAN_PROFILE_VIEWED = 'socialscan_profile_viewed',

    LOGIN_WITH_TG = 'login_with_tg',
}
