<div class="flex flex-col justify-start items-stretch gap-y-2.5 font-bold w-full">
    <div *ngIf="tgService.isOnlyTg" class="px-4 py-2 rounded-xl text-sm text-[#2990FFE5] bg-[#2990FF26] font-normal">
        Please connect a new wallet. Ensure this wallet has not been previously connected or binded with SocialScan.Otherwise, it will result in a connection
        failure.
    </div>

    <div
        *ngFor="let wallet of wallets"
        class="w-full bg-black bg-opacity-5 hover:bg-opacity-10 h-[68px] py-1.5 px-4 rounded-xl flex-between-center gap-x-2.5 cursor-pointer text-base"
        (click)="connect(wallet.name)"
    >
        <div class="gap-x-2.5 flex-start-center">
            <img class="w-10 h-10 object-contain" [src]="wallet.img" [alt]="wallet.displayName" />
            <div class="font-semibold" style="line-height: 1.2">{{wallet.displayName}}</div>
        </div>

        <span *ngIf="connectingWallet === wallet.name" nz-icon nzType="loading" class="text-2xl" nzTheme="outline"></span>
        <span *ngIf="connectingWallet !== wallet.name" nz-icon nzType="antd:v2-long-right-arrow" class="text-2xl" nzTheme="outline"></span>
    </div>

    <ng-container *ngIf="!tgService.isTGLogin">
        <div class="text-center w-full text-xl font-semibold my-4 md:my-6">OR</div>

        <a
            class="w-full bg-black bg-opacity-5 hover:bg-opacity-10 h-[68px] py-1.5 px-4 rounded-xl flex-between-center gap-x-2.5 cursor-pointer text-base"
            [href]="loginTGLink"
        >
            <div class="gap-x-2.5 flex-start-center">
                <img class="w-10 h-10 object-contain" src="/assets/imgs/icon/socialmedia/telegram.png" alt="" />
                <div class="font-semibold" style="line-height: 1.2">Connect Telegram</div>
            </div>

            <span nz-icon nzType="antd:v2-long-right-arrow" class="text-2xl" nzTheme="outline"></span>
        </a>
    </ng-container>
</div>
