import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@src/app/services/common.service';
import { TelegramService } from '@src/app/services/telegram.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private walletStatusService: WalletStatusService,
        private router: Router,
        private tgService: TelegramService,
        private commonService: CommonService
    ) {}

    async canActivate() {
        if (this.tgService.isInTelegramWebApp || this.tgService.isTGLogin) {
            return true;
        }

        if (!this.walletStatusService.isWalletConnected) {
            this.commonService.showLogInModal();
            return false;
        }

        if (!this.walletStatusService.tokenStr$.getValue()) {
            await this.walletStatusService.updateUserToken();
        }

        // If token expired, then disconnect wallet. And redirect to home page.
        if (this.walletStatusService.tokenExp < Date.now()) {
            this.walletStatusService.disconnect();
            this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
            return false;
        }

        return true;
    }
}
