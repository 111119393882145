import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { CommonService } from '@services/common.service';
import { WalletStatusService } from '@services/wallet-status.service';
import { WalletEnum } from '@shared/types/wallet-chain.type';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { ActivatedRoute, Router } from '@angular/router';
import { UseAppKitAccountReturn } from '@reown/appkit';
import { TelegramService } from '@src/app/services/telegram.service';
import { environment } from '@src/environments/environment';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, distinctUntilChanged, filter, Observable, Subscription } from 'rxjs';

declare global {
    interface Window {
        signWithDevKey?: any;
    }
}

@Component({
    selector: 'app-connect-wallet-modal',
    templateUrl: './connect-wallet-modal.component.html',
    styleUrls: ['./connect-wallet-modal.component.less'],
})
export class ConnectWalletModalComponent implements OnInit, OnDestroy {
    WalletEnum = WalletEnum;

    connectingWallet: WalletEnum;

    wallets = this.walletStatusService.detectWalletsBasedOnDevice();

    private wcAccountChangeSub: Subscription;

    private loadingRefs: NzMessageRef[] = [];

    get prefix() {
        return this.walletStatusService.prefix;
    }

    get loginTGLink() {
        return `${environment.telegram.socialScanBotLink}?start=login`;
    }

    constructor(
        @Optional() public modalRef: NzModalRef,
        @Optional() public drawerRef: NzDrawerRef,
        private commonService: CommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private walletStatusService: WalletStatusService,
        public tgService: TelegramService,
        private messageService: NzMessageService
    ) {}

    ngOnInit() {
        this.handleWalletConnect();
    }

    ngOnDestroy() {
        this.wcAccountChangeSub?.unsubscribe();
        this.removeMessage();
    }

    async connect(type: WalletEnum) {
        try {
            switch (type) {
                case WalletEnum.WALLET_CONNECT:
                    await this.connectWalletConnect();
                    break;

                case WalletEnum.META_MASK:
                    await this.connectMetaMask();
                    break;

                case WalletEnum.TRUST_WALLET:
                    await this.connectTrustWallet();
                    break;
            }
        } catch (err: any) {
            if (err.code === -30002) {
                this.commonService.error(err.message || null);
            }
        }
    }

    private async connectWalletConnect() {
        this.connectingWallet = WalletEnum.WALLET_CONNECT;
        this.removeMessage();
        this.wcAccountChangeSub?.unsubscribe();

        try {
            this.walletStatusService.web3Modal.open();

            const accountChanges$ = new Observable<UseAppKitAccountReturn>(subscriber =>
                this.walletStatusService.web3Modal.subscribeAccount(account => subscriber.next(account))
            );

            this.wcAccountChangeSub = accountChanges$
                .pipe(
                    debounceTime(500),
                    distinctUntilChanged((prev, curr) => prev?.isConnected === curr?.isConnected),
                    filter(data => data?.isConnected)
                )
                .subscribe(async ({ address }) => {
                    this.removeMessage();
                    this.loadingRefs.push(this.commonService.loading('Please check your wallet to sign the message...', { nzDuration: 0 }));
                    const { message } = await this.getSdnSignMsg();
                    const sign: string = await (this.walletStatusService.web3Modal.getWalletProvider() as any).request({
                        method: 'personal_sign',
                        params: [message, address],
                    });
                    this.removeMessage();
                    this.handleTGLoginAndLoginStatus(WalletEnum.WALLET_CONNECT, { address, message, sign });
                });
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
            console.log(err);
        }
    }

    private async connectMetaMask() {
        this.connectingWallet = WalletEnum.META_MASK;
        let mmProvider = window.ethereum as any;

        if (mmProvider?.providers?.length) {
            mmProvider = mmProvider.providers?.find((item: any) => item.isMetaMask);
        }

        if (!mmProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://metamask.app.link/dapp/${window.location.protocol}//${window.location.host}/${window.location.pathname}`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await mmProvider.request({ method: 'eth_requestAccounts', params: [] });
            const { message } = await this.getSdnSignMsg();

            const sign = await mmProvider.request({ method: 'personal_sign', params: [message, accounts[0]] });

            this.handleTGLoginAndLoginStatus(WalletEnum.META_MASK, { address: accounts[0], message, sign });
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err?.message);
            this.connectingWallet = null;
        }
    }

    private async connectTrustWallet() {
        const trustWalletProvider = this.walletStatusService.getTrustWalletFromWindow();
        this.connectingWallet = WalletEnum.TRUST_WALLET;

        if (!trustWalletProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://link.trustwallet.com/open_url?url=${window.location.protocol}//${window.location.host}/${window.location.pathname}&coin_id=60`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await trustWalletProvider.request({ method: 'eth_requestAccounts' });
            const { message } = await this.getSdnSignMsg();
            const sign = await trustWalletProvider.request({
                method: 'personal_sign',
                params: [message, accounts[0]],
            });
            this.handleTGLoginAndLoginStatus(WalletEnum.TRUST_WALLET, { address: accounts[0], message, sign });
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
        }
    }

    private async getSdnSignMsg() {
        this.walletStatusService.msgToSign = this.walletStatusService.getMessageToSign();
        return { message: this.walletStatusService.msgToSign };
    }

    private close() {
        this.modalRef?.triggerCancel();
        this.drawerRef?.close();
    }

    private async goInvitation() {
        return new Promise(() => {
            const sub = this.walletStatusService.tokenStr$.pipe(filter(res => !!res)).subscribe(() => {
                if (this.walletStatusService.checkIfSignupCompleted()) {
                    sub.unsubscribe();

                    if (this.activatedRoute.snapshot.url.length === 0) {
                        this.router.navigate(['/', 'my-profile']);
                    }
                } else {
                    this.commonService.showEmailInvitationModal();
                }
            });
        });
    }

    private handleTGLoginAndLoginStatus(wallet: WalletEnum, data: { address: string; message: string; sign: string }) {
        if (this.tgService.isInTelegramWebApp || this.tgService.isTGLogin) {
            this.tgService
                .connectWalletWithTG({
                    wallet_address: data.address,
                    message: data.message,
                    signature: data.sign,
                })
                .then(() => {
                    this.walletStatusService.setSessionData({
                        wallet,
                        walletAddress: data.address,
                        signature: data.sign,
                    });
                    this.commonService.success('Connection successful!');
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch(err => {
                    if (err.status === 400) {
                        this.commonService.error('Connection failed. Please select a new Wallet that has never been connected to SocialScan.');
                    } else {
                        this.commonService.errorMessageByResponse(err);
                    }
                })
                .finally(() => this.close());
        } else {
            this.walletStatusService.setSessionData({
                wallet,
                walletAddress: data.address,
                signature: data.sign,
            });
            this.goInvitation();
            this.close();
        }
    }

    private removeMessage() {
        this.loadingRefs.forEach(ref => {
            if (ref) {
                this.messageService.remove(ref.messageId);
            }
        });
    }

    private handleWalletConnect() {
        // 有时 walletconnect 没有退出登录，所以需要手动退出
        if (this.walletStatusService.isWalletConnected && this.walletStatusService.wallet === WalletEnum.WALLET_CONNECT) {
            this.walletStatusService.web3Modal.disconnect();
        }
    }
}
