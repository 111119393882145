import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LOCAL_STORAGE_KEY_ENUM } from '../shared/types/storage.type';

type QueryParamValue = string | number | boolean | null | undefined;

interface IRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    api?: 'admin-api' | 'data-api' | 'mock' | 'socialscan' | string;
    host?: string;
    data?: unknown;
    params?: HttpParams;
    queryParams?: Record<string, QueryParamValue | QueryParamValue[]>;
}

export interface IResponseError {
    status: HttpErrorResponse['status'];
    body: {
        code: number;
        message: string;
    };
    message?: string;
}

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient) {}

    sendUnauthorizedRequest$<T>(requestOptions: IRequestOptions): Observable<T> {
        const host = this.getHost(requestOptions.api);

        const url = `${(requestOptions.host || host) ?? environment.adminApiUrl}${requestOptions.url}`;

        return this.http
            .request<T>(requestOptions.method, url, {
                body: requestOptions.data ? requestOptions.data : null,
                params: requestOptions.params ?? {},
            })
            .pipe(catchError(error => this.handleError(error)));
    }

    sendRequest<T>(requestOptions: IRequestOptions): Promise<T> {
        return firstValueFrom(this.sendRequest$<T>(requestOptions));
    }

    sendRequest$<T>(requestOptions: IRequestOptions): Observable<T> {
        const host = this.getHost(requestOptions.api);
        const url = new URL(`${(requestOptions.host || host) ?? environment.adminApiUrl}${requestOptions.url}`);
        if (requestOptions.queryParams) {
            Object.keys(requestOptions.queryParams).forEach(key => {
                const value = requestOptions.queryParams[key];
                if (Array.isArray(value)) {
                    value.forEach(v => url.searchParams.append(key, String(v)));
                } else {
                    url.searchParams.append(key, String(value));
                }
            });
        }

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TOKEN) ?? '',
                apifoxToken: requestOptions.api === 'mock' ? 'AIGIYFZWbwfaru1ird2Xx' : '',
                'Content-Type': 'application/json',
            }),
        };

        switch (requestOptions.method) {
            case 'GET':
                return this.http.get<T>(url.toString(), httpOptions).pipe(catchError(error => this.handleError(error)));
            case 'DELETE':
                return this.http.delete<T>(url.toString(), httpOptions).pipe(catchError(error => this.handleError(error)));
            case 'POST':
                return this.http.post<T>(url.toString(), requestOptions.data, httpOptions).pipe(catchError(error => this.handleError(error)));
            case 'PUT':
                return this.http.put<T>(url.toString(), requestOptions.data, httpOptions).pipe(catchError(error => this.handleError(error)));
        }
    }

    sendUnauthorizedRequest<T>(requestOptions: IRequestOptions): Promise<T> {
        return firstValueFrom(this.sendUnauthorizedRequest$<T>(requestOptions));
    }

    get<T>(url: string, queryParams?: Record<string, QueryParamValue | QueryParamValue[]>, api?: IRequestOptions['api']): Observable<T> {
        return this.sendRequest$<T>({
            method: 'GET',
            url,
            queryParams,
            api,
        });
    }

    post<T>(url: string, data?: any, api?: IRequestOptions['api']): Observable<T> {
        return this.sendRequest$<T>({
            method: 'POST',
            url,
            data,
            api,
        });
    }

    put<T>(url: string, data?: any, api?: IRequestOptions['api']): Observable<T> {
        return this.sendRequest$<T>({
            method: 'PUT',
            url,
            data,
            api,
        });
    }

    delete<T>(url: string, queryParams?: Record<string, QueryParamValue | QueryParamValue[]>, api?: IRequestOptions['api']): Observable<T> {
        return this.sendRequest$<T>({
            method: 'DELETE',
            url,
            queryParams,
            api,
        });
    }

    private handleError(error: HttpErrorResponse) {
        const responseErr = {
            status: error.status,
            body:
                error.status < 500
                    ? error.error
                    : {
                          code: error.error?.code,
                          message: 'Oops! Something went wrong. Please try again.',
                      },
        } as IResponseError;

        return throwError(() => responseErr);
    }

    private getHost(apiString = '') {
        switch (apiString) {
            case 'data-api':
                return environment.dataApiUrl;
            case 'mock':
                return 'https://mock.apipark.cn/m1/4288465-0-default';
            case 'socialscan':
                return environment.socialscanApiUrl;
            default:
                return environment.adminApiUrl;
        }
    }
}
