import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { TelegramService } from './telegram.service';
import { WalletStatusService } from './wallet-status.service';

@Injectable({
    providedIn: 'root',
})
// SocialScan 改版后，统筹 wallet 跟 tg 的 user 信息
export class UserService {
    public get userImg() {
        if (this.tgService.isOnlyTg) {
            return this.tgService.tgUserData?.photo_url || '/assets/imgs/icon/user.png';
        } else {
            if (this.walletStatusService.userProfile?.profile_image) {
                return this.walletStatusService.userProfile?.profile_image;
            } else {
                return this.commonService.getAddressIcon(this.walletStatusService.walletAddress || this.userName) || '/assets/imgs/icon/user.png';
            }
        }
    }

    public get userName() {
        if (this.tgService.isOnlyTg) {
            return this.tgService.tgUserData?.username || 'N/A';
        } else {
            return this.walletStatusService.userProfileName;
        }
    }

    constructor(private commonService: CommonService, private walletStatusService: WalletStatusService, private tgService: TelegramService) {}
}
